.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #09d3ac;
}

.content {
  margin: 80px auto 0 auto;
  max-width: 1200px;
}

tbody.MuiTableBody-root > tr:hover {
  background-color: #efefef;
}

tbody.MuiTableBody-root >:nth-of-type(2n)
{
    background-color: #fafafa;
}

.MuiFormControlLabel-root {
  margin-left: unset !important;
}

li.MuiGridListTile-root {
  height: unset !important;
}